import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { UserOrganizationContextProvider } from '@modules-deprecated/app/organization'
import { Creditors } from '@views/creditors'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'

const MODULE_NAME = 'creditors-module'

class CreditorsWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
}

interface CreditorsModuleProps {
  organizationId: string
  onNavigate: EmberNavigate
}

const CreditorsModule = ({ onNavigate, organizationId }: CreditorsModuleProps): ReactElement => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <HashRouter>
        <EmberRouterContextProvider onNavigate={onNavigate}>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <Creditors />
          </UserOrganizationContextProvider>
        </EmberRouterContextProvider>
      </HashRouter>
    </ThemeProvider>
  </QueryClientProvider>
)

const CreditorsCustomElement = createCustomElement(CreditorsModule, CreditorsWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, CreditorsCustomElement)

export default CreditorsCustomElement
