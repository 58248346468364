import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchExternalUnrecognizedVatRates } from '../query-api'

export const useFetchExternalUnrecognizedVatRates = () => {
  const { data, ...rest } = useQuery(QueryKeys.ExternalUnrecognizedVatRates, () => fetchExternalUnrecognizedVatRates())

  return { taxRates: data?.data.taxRates, ...rest }
}
