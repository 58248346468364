import { Button, ButtonsGroup, Modal, ModalProps, Text } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../../config/queryClient'
import { QueryKeys } from '../../../../../../../../enums/queryKeys'
import { ExternalInvoicesSyncJobStatus } from '../../../../../../enums/externalInvoicesSyncJobStatus'
import { useUpdateExternalInvoicesSync } from '../../../../../../hooks/useUpdateExternalInvoicesSync'
import { useSyncErrorTopBar } from '../../contexts/syncErrorTopBarContext'
import { notifySyncUpdateError } from '../../utils/notifySyncUpdateError'

export const SkipAndCompleteSyncModal = (modalProps: ModalProps): ReactElement => {
  const { t } = useTranslation()
  const { closeSkipAndCompleteSyncModal, openUnrecognizedVatRatesModal } = useSyncErrorTopBar()
  const { update: updateSyncJobStatus, isProcessing } = useUpdateExternalInvoicesSync({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.ExternalInvoicesSync)
      closeSkipAndCompleteSyncModal()
    },
    onError: () => {
      notifySyncUpdateError(t)
    },
  })

  const handleCancelButtonClick = useCallback(() => {
    closeSkipAndCompleteSyncModal()
    openUnrecognizedVatRatesModal()
  }, [closeSkipAndCompleteSyncModal, openUnrecognizedVatRatesModal])

  const handleCompleteButtonClick = useCallback(() => {
    updateSyncJobStatus(ExternalInvoicesSyncJobStatus.UserCompleted)
  }, [updateSyncJobStatus])

  return (
    <Modal size="s" {...modalProps}>
      <Modal.Header title={t('external_invoices_sync_skip_and_complete_modal.complete_sync')} />
      <Modal.Body>
        <Text>{t('external_invoices_sync_skip_and_complete_modal.invoices_will_be_lost')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button onClick={handleCancelButtonClick} variant="text">
            {t('cancel')}
          </Button>
          <Button loading={isProcessing} onClick={handleCompleteButtonClick}>
            {t('complete')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
