import { Button, IconButton, Space, Text, useModal, withModalConditionalRender } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'

import { LocalStorageKeys } from '../../../../enums/localStorageKeys'
import { ModalId } from '../../../../enums/modalId'
import { useShouldDisplayExternalInvoicesSyncInitiateAction } from '../../hooks/useShouldDisplayExternalInvoicesSyncInitiateAction'
import { ExternalInvoicesSyncModal } from '../ExternalInvoicesSyncModal'
import * as Styled from './styles'

const ExternalInvoicesSyncModalConditional = withModalConditionalRender(ExternalInvoicesSyncModal)

const externalInvoicesSyncModalId = ModalId.ExternalInvoicesSyncModal

export const ExternalInvoicesSyncDashboardBanner = (): ReactElement | null => {
  const { t } = useTranslation()
  const [isBannerClosed, setIsBannerClosed] = useLocalStorage(LocalStorageKeys.DashboardExternalInvoicesSyncBanner)
  const { shouldDisplayExternalInvoicesSyncInitiateAction } = useShouldDisplayExternalInvoicesSyncInitiateAction()
  const { open: openExternalInvoicesSyncModal } = useModal(externalInvoicesSyncModalId)

  const shouldDisplayBanner = useMemo(() => {
    return shouldDisplayExternalInvoicesSyncInitiateAction && !isBannerClosed
  }, [isBannerClosed, shouldDisplayExternalInvoicesSyncInitiateAction])

  const handleShowMeHowButtonClick = useCallback(() => {
    openExternalInvoicesSyncModal()
  }, [openExternalInvoicesSyncModal])

  const handleCloseButtonClick = useCallback(() => {
    setIsBannerClosed(true)
  }, [setIsBannerClosed])

  if (!shouldDisplayBanner) {
    return null
  }

  return (
    <>
      <Styled.ExternalInvoicesSyncBannerWrapper>
        <Text variant="h3">{t('dashboard.banner.external_invoices_sync.sync_your_invoice_history')}</Text>
        <Space />
        <Button iconRight="arrowRight" onClick={handleShowMeHowButtonClick} size="m" variant="secondary">
          {t('dashboard.banner.external_invoices_sync.show_me_how')}
        </Button>
        <Styled.CloseIconWrapper>
          <IconButton icon="xSign" onClick={handleCloseButtonClick} size="m" />
        </Styled.CloseIconWrapper>
      </Styled.ExternalInvoicesSyncBannerWrapper>
      <ExternalInvoicesSyncModalConditional id={externalInvoicesSyncModalId} />
    </>
  )
}
