import { InputRef, PORTAL_CLASS_NAME, useNavItemsNavigation } from '@design-system'

import isEmpty from 'lodash/isEmpty'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useClickAway, useDebounce, useKey } from 'react-use'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { isBillsModulePath } from '@views/bills/utils/isBillsModulePath'
import { settingsRouteToEmberSettingsRoute } from '@views/settings/constants/settingsRouteToEmberSettingsRoute'
import { useOrganizationSettingsTabItems } from '@views/settings/elements/SettingsNavigation/hooks/useOrganizationSettingsTabItems'
import { useUserSettingsTabItems } from '@views/settings/elements/SettingsNavigation/hooks/useUserSettingsTabItems'
import { isSettingsModulePath } from '@views/settings/utils/isSettingsModulePath'
import { routeToInternalSettings } from '@views/settings/utils/routeToInternalSettings'
import { umbrellaRouteToEmberRoute } from '@views/umbrella/constants/umbrellaRouteToEmberRoute'
import { useUmbrellaUserSettingsTabItems } from '@views/umbrella/routes/umbrellaSettings/elements/UmbrellaSettingsNavigation/hooks/useUmbrellaUserSettingsTabItems'
import { routeToUmbrella } from '@views/umbrella/utils/routeToUmbrella'

import { useEmberRouter } from '../../contexts/emberRouterContext'
import { useGlobalSearch } from '../../contexts/globalSearchContext'
import { CustomEvent } from '../../enums/customEvent'
import { EmberRoute } from '../../enums/emberRoute'
import { QueryKeys } from '../../enums/queryKeys'
import { Timeout } from '../../types/timeout'
import { dispatchCustomEvent } from '../../utils/customEvent'
import { useFastMenuItems } from '../Menu/elements/MenuActions/hooks/useFastMenuItems'
import { useMenuItems } from '../Menu/elements/MenuItems/hooks/useMenuItems'
import { globalSearchTriggerDataId } from './constants/triggerDataId'
import { GlobalSearchInput } from './elements/GlobalSearchInput'
import { GlobalSearchResults } from './elements/GlobalSearchResults'
import { GlobalSearchTips } from './elements/GlobalSearchTips'
import { searchGlobally } from './query-api'
import * as Styled from './styles'
import { SearchResultItem } from './types/searchResultItem'
import { combineSearchResultItems } from './utils/combineSearchResultItems'
import { getCustomSearchItems } from './utils/getCustomSearchItems'
import { getSearchResultsItems } from './utils/getSearchResultsItems'

const SEARCH_CHANGE_DEBOUNCE_MS = 250
const SEARCH_CLOSE_ANIMATION_TIMEOUT_MS = 250
const GLOBAL_SEARCH_QUERY_CACHE_TIME_MS = 0 // no need to cache it as it's too sensitive query

export const GlobalSearch = () => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const [{ isOpen, isRendered }, dispatch] = useGlobalSearch()
  const { navigate } = useEmberRouter()
  const { organization } = useUserOrganization()
  const [searchValue, setSearchValue] = useState('')
  const [searchValueDebounced, setSearchValueDebounced] = useState('')
  const wrapperRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<InputRef>(null)
  const { items: menuItems } = useMenuItems()
  const { items: fastMenuItems } = useFastMenuItems()
  const { items: organizationSettingsTabItems } = useOrganizationSettingsTabItems()
  const { items: userSettingsTabItems } = useUserSettingsTabItems()
  const { items: settingsTabItemsForUmbrella } = useUmbrellaUserSettingsTabItems()
  const closeTimeout = useRef<Timeout>()

  const { data: searchResults, isLoading } = useQuery(
    [QueryKeys.GlobalSearch, [organization?.id, searchValueDebounced]],
    () => searchGlobally(organization?.id || '', searchValueDebounced),
    {
      enabled: !!organization && searchValueDebounced.length >= 2,
      cacheTime: GLOBAL_SEARCH_QUERY_CACHE_TIME_MS,
    },
  )

  const settingsTabItemsForOrganization = useMemo(
    () => [...organizationSettingsTabItems, ...userSettingsTabItems],
    [organizationSettingsTabItems, userSettingsTabItems],
  )

  const settingsTabItems = umbrella ? settingsTabItemsForUmbrella : settingsTabItemsForOrganization

  const customSearchItems = useMemo(
    () => getCustomSearchItems(menuItems, fastMenuItems, settingsTabItems, searchValue, t),
    [menuItems, fastMenuItems, settingsTabItems, searchValue, t],
  )
  const searchResultItems = useMemo(() => (searchResults ? getSearchResultsItems(searchResults) : []), [searchResults])
  const items = useMemo(
    () => combineSearchResultItems(customSearchItems, searchResultItems),
    [customSearchItems, searchResultItems],
  )

  const [, cancelSearchValueDebounce] = useDebounce(
    () => {
      setSearchValueDebounced(searchValue)
    },
    SEARCH_CHANGE_DEBOUNCE_MS,
    [searchValue],
  )

  useEffect(() => {
    let requestAnimationId: number

    if (isOpen) {
      // wait a frame so there is no initial shortcut text inside the search input
      requestAnimationId = window.requestAnimationFrame(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        inputRef?.current?.focus()
      })
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    inputRef?.current?.blur()

    return () => {
      if (requestAnimationId) {
        window.cancelAnimationFrame(requestAnimationId)
      }

      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current)
      }
    }
  }, [isOpen])

  const close = useCallback(() => {
    dispatch({ type: 'CLOSE' })

    closeTimeout.current = setTimeout(() => {
      setSearchValue('')
      setSearchValueDebounced('')
      cancelSearchValueDebounce()
    }, SEARCH_CLOSE_ANIMATION_TIMEOUT_MS)
  }, [dispatch, cancelSearchValueDebounce])

  const clearInputThenClose = useCallback(() => {
    if (searchValueDebounced) {
      setSearchValue('')
      setSearchValueDebounced('')
      cancelSearchValueDebounce()
      return
    }

    dispatch({ type: 'CLOSE' })
  }, [searchValueDebounced, dispatch, cancelSearchValueDebounce])

  const handleClickAway = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const searchTriggerElements = document.querySelectorAll(`[data-id='${globalSearchTriggerDataId}']`)

      const hasClickedOnTrigger = Array.from(searchTriggerElements).some(
        (element) => element.contains(target) || element === target,
      )

      if (hasClickedOnTrigger) {
        return
      }

      close()
    },
    [close],
  )

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchValue(value)
  }, [])

  const navigateToItem = useCallback(
    (item: SearchResultItem) => {
      const { category, routeTo, routeId } = item
      const isSettingsEmberRoute = !!routeTo && Object.values(settingsRouteToEmberSettingsRoute).includes(routeTo)
      const isUmbrellaEmberRoute = !!routeTo && Object.values(umbrellaRouteToEmberRoute).includes(routeTo)

      // there's an issue when we try to navigate using ember routing to the same module which is currently opened
      // that's why it's better to just invoke a "history" method in this module using custom event
      if (routeTo === EmberRoute.BillsNew && isBillsModulePath()) {
        dispatchCustomEvent(CustomEvent.CreateNewBillClicked)
        return
      }

      if (isUmbrellaEmberRoute) {
        routeToUmbrella(routeTo)
        return
      }

      if (isSettingsEmberRoute && isSettingsModulePath()) {
        routeToInternalSettings(routeTo)
        return
      }

      if (category === 'product') {
        navigate(EmberRoute.Products)
        return
      }

      if (routeTo && routeId) {
        navigate(routeTo, routeId)
        return
      }

      if (routeTo) {
        navigate(routeTo)
      }
    },
    [navigate],
  )

  const handleSearchItemClick = useCallback(
    (item: SearchResultItem) => {
      navigateToItem(item)

      if (!isEmpty(item)) {
        close()
      }
    },
    [close, navigateToItem],
  )

  // Shortcuts

  useKey('Escape', clearInputThenClose, undefined, [searchValueDebounced])
  useClickAway(wrapperRef, handleClickAway)

  const focusedId = useNavItemsNavigation({
    items,
    isActive: isOpen,
    onItemSelect: handleSearchItemClick,
  })

  if (!isRendered) {
    return null
  }

  return ReactDOM.createPortal(
    <Styled.GlobalSearchWrapper className={PORTAL_CLASS_NAME} ref={wrapperRef} visible={isOpen}>
      <GlobalSearchInput
        isLoading={isLoading}
        onChange={handleInputChange}
        onClose={close}
        ref={inputRef}
        value={searchValue}
      />

      {items.length > 0 ? (
        <GlobalSearchResults
          items={items}
          onItemClick={handleSearchItemClick}
          searchValue={searchValue}
          focusedId={focusedId}
        />
      ) : (
        <GlobalSearchTips />
      )}
    </Styled.GlobalSearchWrapper>,
    document.body,
  )
}
