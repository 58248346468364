import { useQueryClient } from 'react-query'

import {
  OrganizationSettingsResponseData,
  useUpdateOrganizationSettings,
  useUserOrganization,
  useUserOrganizationSettings,
} from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../enums/queryKeys'
import { formatDate } from '../../../utils'
import { FINANCING_DISMISSED_DATE } from '../constants/financingSettingsKeys'

interface AgerasFinancingProps {
  cancelDismissal: () => void
  dismiss: (forever: boolean) => void
  isDismissed: boolean
  isLoading: boolean
  isUpdating: boolean
}

export const useAgerasFinancing = (): AgerasFinancingProps => {
  const queryClient = useQueryClient()
  const { organization } = useUserOrganization()
  const { organizationSettings, isLoading: isOrganizationSettingsLoading } = useUserOrganizationSettings()
  const isHiddenFromDate = organizationSettings?.financingDismissedDate

  const { update: updateOrganizationSettings, isLoading: isUpdatingOrganizationSettings } =
    useUpdateOrganizationSettings({
      onMutate: async (setting) => {
        await queryClient.cancelQueries(QueryKeys.UserOrganizationSettings)

        const previousSettings: OrganizationSettingsResponseData = queryClient.getQueryData([
          QueryKeys.UserOrganizationSettings,
          organization?.id,
        ]) as OrganizationSettingsResponseData

        const updatedSettings: OrganizationSettingsResponseData = {
          settings: {
            ...previousSettings.settings,
            [setting.key]: setting.value,
          },
        }

        queryClient.setQueryData([QueryKeys.UserOrganizationSettings, organization?.id], updatedSettings)

        return previousSettings
      },
      onError: (error, _, context) => {
        queryClient.setQueryData([QueryKeys.UserOrganizationSettings, organization?.id], context)
        console.error(error)
      },
      onSettled: () => {
        queryClient.invalidateQueries(QueryKeys.UserOrganizationSettings)
      },
    })

  const dismiss = (forever = false) => {
    if (!organization?.id) {
      return
    }

    updateOrganizationSettings({
      key: FINANCING_DISMISSED_DATE,
      organizationId: organization.id,
      value: JSON.stringify({
        date: formatDate(new Date()),
        forever,
      }),
    })
  }

  const cancelDismissal = () => {
    if (!organization?.id) {
      return
    }

    updateOrganizationSettings({
      key: FINANCING_DISMISSED_DATE,
      organizationId: organization.id,
      value: '',
    })
  }

  return {
    cancelDismissal,
    dismiss,
    isDismissed: !!isHiddenFromDate,
    isLoading: isOrganizationSettingsLoading,
    isUpdating: isUpdatingOrganizationSettings,
  }
}
