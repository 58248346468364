import { useModal } from '@design-system'

import { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo, useState } from 'react'

import { SettingsRoute } from '@views/settings/enums/settingsRoute'

import { useEmberRouter } from '../../../../../../../contexts/emberRouterContext'
import { CustomEvent } from '../../../../../../../enums/customEvent'
import { EmberRoute } from '../../../../../../../enums/emberRoute'
import { ModalId } from '../../../../../../../enums/modalId'
import { dispatchCustomEvent } from '../../../../../../../utils/customEvent'
import { useFetchExternalUnrecognizedVatRates } from '../../../../../hooks/useFetchExternalUnrecognizedVatRates'

interface ContextState {
  closeSkipAndCompleteSyncModal: () => void
  closeUnrecognizedVatRatesModal: () => void
  openSkipAndCompleteSyncModal: () => void
  openUnrecognizedVatRatesModal: () => void
  redirectAndOpenCreateVatRateModal: () => void
  shouldDisplaySyncErrorActions?: boolean
  unrecognizedVatRates?: string[]
  viewPossibleSyncErrorActions: () => void
}

const SyncErrorTopBarContext = createContext<ContextState | undefined>(undefined)

interface SyncErrorTopBarContextProps {
  children: ReactNode
}

export const SyncErrorTopBarContextProvider = ({ children }: SyncErrorTopBarContextProps): ReactElement => {
  const [shouldDisplaySyncErrorActions, setShouldDisplaySyncErrorActions] = useState(false)
  const { navigate } = useEmberRouter()
  const { taxRates } = useFetchExternalUnrecognizedVatRates()
  const { open: openSkipAndCompleteSyncModal, close: closeSkipAndCompleteSyncModal } = useModal(
    ModalId.SkipAndCompleteSyncModal,
  )
  const { open: openUnrecognizedVatRatesModal, close: closeUnrecognizedVatRatesModal } = useModal(
    ModalId.UnrecognizedVatRatesModal,
  )

  const unrecognizedVatRates = useMemo(() => {
    return taxRates?.map((taxRate) => `${taxRate.rate * 100}%`)
  }, [taxRates])

  const redirectAndOpenCreateVatRateModal = useCallback(() => {
    const isVatRatesSettingsPath = window.location.href.includes(SettingsRoute.OrganizationVatRates)

    if (isVatRatesSettingsPath) {
      dispatchCustomEvent(CustomEvent.CreateVatRatesClicked)
      return
    }

    navigate(EmberRoute.SettingsVatRates, { queryParams: { openCreateVatRateModal: true } })
  }, [navigate])

  const viewPossibleSyncErrorActions = useCallback(() => {
    setShouldDisplaySyncErrorActions(true)
    openUnrecognizedVatRatesModal()
  }, [openUnrecognizedVatRatesModal])

  return (
    <SyncErrorTopBarContext.Provider
      value={{
        closeSkipAndCompleteSyncModal,
        closeUnrecognizedVatRatesModal,
        openSkipAndCompleteSyncModal,
        openUnrecognizedVatRatesModal,
        redirectAndOpenCreateVatRateModal,
        shouldDisplaySyncErrorActions,
        unrecognizedVatRates,
        viewPossibleSyncErrorActions,
      }}
    >
      {children}
    </SyncErrorTopBarContext.Provider>
  )
}

export const useSyncErrorTopBar = () => {
  const context = useContext(SyncErrorTopBarContext)

  if (!context) {
    throw new Error('SyncErrorTopBarContextProvider is missing in the module!')
  }

  return context
}
