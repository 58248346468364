import { Alert, Button, Flex, Spacing } from '@design-system'

import isNumber from 'lodash/isNumber'
import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../config/queryClient'
import { QueryKeys } from '../../../../../../enums/queryKeys'
import {
  useUpdateOrganizationSettings,
  useUserOrganization,
} from '../../../../../../modules-deprecated/app/organization'
import { useExternalInvoicesSyncProgress } from '../../contexts/externalInvoicesSyncProgressContext'

export const SyncCompletedTopBar = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { update: updateSettings, isLoading: isUpdating } = useUpdateOrganizationSettings({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.UserOrganizationSettings)
    },
  })
  const { processedDocumentsCount } = useExternalInvoicesSyncProgress()

  const completedSyncAlertText = isNumber(processedDocumentsCount)
    ? t('external_invoices_sync_top_bar.completed.syncing_completed', {
        processedDocumentsCount,
      })
    : t('external_invoices_sync_top_bar.completed.syncing_completed.no_documents')

  const handleCloseButtonClick = useCallback(() => {
    if (organization?.id) {
      updateSettings({
        key: 'externalInvoicesSyncShowTopBar',
        organizationId: organization?.id,
        value: '0',
      })
    }
  }, [organization?.id, updateSettings])

  return (
    <Alert variant="success">
      <Flex alignItems="center" gap={Spacing.S}>
        {completedSyncAlertText}
        <Button loading={isUpdating} onClick={handleCloseButtonClick} size="m" variant="secondary">
          {t('close')}
        </Button>
      </Flex>
    </Alert>
  )
}
