import { isEmpty } from 'lodash'
import { ReactElement, ReactNode } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { AppBrand } from '../../../types/appBrand'

export interface BrandProtectedComponentProps {
  brand: AppBrand | AppBrand[]
  children?: ReactNode
  unauthorizedNode?: ReactNode
  loadingNode?: ReactNode
}

export const BrandProtectedComponent = ({
  brand,
  children,
  loadingNode = null,
  unauthorizedNode = null,
}: BrandProtectedComponentProps): ReactElement | null => {
  const { organization, isLoading } = useUserOrganization()
  const { umbrella } = useUmbrella()

  const organizationBrand = organization?.brand

  if (!isEmpty(umbrella)) {
    return <>{children}</>
  }

  if (isLoading || !organizationBrand) {
    return <>{loadingNode}</>
  }

  const isBrandAuthorized = Array.isArray(brand) ? brand.includes(organizationBrand) : organizationBrand === brand

  if (!isBrandAuthorized) {
    return <>{unauthorizedNode}</>
  }

  return <>{children}</>
}
