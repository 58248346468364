import { isBefore } from 'date-fns'
import { useMemo } from 'react'

import { useOrganizationBrand } from '../../../hooks/useOrganizationBrand'
import { useUserOrganization } from '../../../modules-deprecated/app/organization'
import { ExternalInvoicesSyncStatus } from '../enums/externalInvoicesSyncStatus'
import { useFetchExternalInvoicesSync } from './useFetchExternalInvoicesSync'

// it is a business requirement, it does not make sense to offer historical sync
// to the users who signed up after this date, because all their invoices
// will be new and they will be synced automatically
const latestAllowedCreatedTime = new Date('2024-08-15')

export const useShouldDisplayExternalInvoicesSyncInitiateAction = () => {
  const { organization } = useUserOrganization()
  const { isBrandAgeras } = useOrganizationBrand()
  const { syncData } = useFetchExternalInvoicesSync()

  const shouldDisplayExternalInvoicesSyncInitiateAction = useMemo(() => {
    const isSyncNotInitiatedYet = syncData?.syncStatus === ExternalInvoicesSyncStatus.NotInitiated
    const organizationCreatedTime = organization?.createdTime
    const isCountryFrance = organization?.countryId === 'FR'
    const isOrganizationCreatedBeforeLatestAllowedTime = organizationCreatedTime
      ? isBefore(new Date(organizationCreatedTime), latestAllowedCreatedTime)
      : false

    return isSyncNotInitiatedYet && isOrganizationCreatedBeforeLatestAllowedTime && isCountryFrance && isBrandAgeras
  }, [isBrandAgeras, organization?.countryId, organization?.createdTime, syncData?.syncStatus])

  return { shouldDisplayExternalInvoicesSyncInitiateAction }
}
