import { ProcessingOverlay } from '@components'
import { Color, DateInput, RadioGroup, Space, Spacing, Text } from '@design-system'

import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../hooks'
import { getImgPath } from '../../../../../../utils/getImgPath'
import { EARLIEST_SYNC_START_DATE } from '../../constants/earliestSyncStartDate'
import { useExternalInvoicesSyncModal } from '../../contexts/externalInvoicesSyncModalContext'
import { SyncTimePeriodMode } from '../../enums/syncTimePeriodMode'
import { ExternalInvoicesSyncFormSchema } from '../../utils/formData'
import { SyncTimePeriodModeRadio } from './elements/SyncTimePeriodModeRadio'

const externalInvoicesSyncIllustration = getImgPath('/external-invoices-sync/modal-external-invoices-sync.svg')

export const ModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<ExternalInvoicesSyncFormSchema>()
  const { isProcessing, shouldShowSyncStartDateField } = useExternalInvoicesSyncModal()

  return (
    <>
      {isProcessing && <ProcessingOverlay color={Color.White} />}
      <img src={externalInvoicesSyncIllustration} alt="External invoices sync modal illustration" />
      <Space size="xl" />
      <Text>{t('external_invoices_sync_modal.description')}</Text>
      <Space size="xl" />
      <FormItem
        name="syncTimePeriodMode"
        render={({ field: { value, onChange, ...props } }) => (
          <RadioGroup {...props} value={value} onChange={onChange} spacing={Spacing.XL}>
            <SyncTimePeriodModeRadio
              header={t('external_invoices_sync_modal.radio.sync_all_invoices.header')}
              subheader={t('external_invoices_sync_modal.radio.sync_all_invoices.subheader', {
                date: EARLIEST_SYNC_START_DATE,
              })}
              tooltipLabel={t('external_invoices_sync_modal.radio.sync_all_invoices.tooltip_label')}
              value={SyncTimePeriodMode.CurrentFiscalYear}
            />
            <SyncTimePeriodModeRadio
              header={t('external_invoices_sync_modal.radio.sync_from_selected_date.header')}
              subheader={t('external_invoices_sync_modal.radio.sync_from_selected_date.subheader', {
                date: EARLIEST_SYNC_START_DATE,
              })}
              tooltipLabel={
                <Trans i18nKey="external_invoices_sync_modal.radio.sync_from_selected_date.tooltip_label">
                  Reasons to choose a specific sync date: <br /> 1. Start of Fiscal Year/Quarter. <br /> 2. Avoiding
                  Unnecessary Data Overload <br /> 3. Excluding Past Errors
                </Trans>
              }
              value={SyncTimePeriodMode.FromSelectedDate}
            />
          </RadioGroup>
        )}
      />
      {shouldShowSyncStartDateField && (
        <>
          <Space size="xl" />
          <FormItem
            name="syncStartDate"
            render={({ field }) => (
              <DateInput {...field} minDate={new Date(EARLIEST_SYNC_START_DATE)} maxDate={new Date()} />
            )}
          />
        </>
      )}
    </>
  )
}
