import { Button, ButtonsGroup } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSyncErrorTopBar } from '../../../../contexts/syncErrorTopBarContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { closeUnrecognizedVatRatesModal, openSkipAndCompleteSyncModal, redirectAndOpenCreateVatRateModal } =
    useSyncErrorTopBar()

  const handleCreateVatRatesButtonClick = useCallback(() => {
    closeUnrecognizedVatRatesModal()
    redirectAndOpenCreateVatRateModal()
  }, [closeUnrecognizedVatRatesModal, redirectAndOpenCreateVatRateModal])

  const handleSkipAndCompleteButtonClick = useCallback(() => {
    closeUnrecognizedVatRatesModal()
    openSkipAndCompleteSyncModal()
  }, [closeUnrecognizedVatRatesModal, openSkipAndCompleteSyncModal])

  return (
    <ButtonsGroup>
      <Button onClick={handleCreateVatRatesButtonClick} variant="secondary">
        {t('external_invoices_sync_unrecognized_vat_rates_modal.create_rates_button')}
      </Button>
      <Button onClick={handleSkipAndCompleteButtonClick}>
        {t('external_invoices_sync_unrecognized_vat_rates_modal.skip_and_complete_button')}
      </Button>
    </ButtonsGroup>
  )
}
