import { Color, Flex, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { SIDE_NAVBAR_WIDTH } from '../../constants/sideNavbarWidth'

interface AppButtonWrapperProps {
  isClickable?: boolean
  backgroundColor: Color
  buttonColor?: Color
  hoverButtonColor?: Color | string
  labelColor?: Color
  onClick?: () => void
}

export const AppButtonWrapper = styled(Flex)<AppButtonWrapperProps>`
  align-items: center;
  justify-content: center;
  width: ${SIDE_NAVBAR_WIDTH};
  height: ${SIDE_NAVBAR_WIDTH};

  ${({ isClickable, backgroundColor, buttonColor }) => {
    return css`
      cursor: ${isClickable ? 'pointer' : 'default'};
      background-color: ${backgroundColor};

      // A "frame" around the "Ageras" button
      ${!isClickable &&
      css`
        &:after {
          width: 36px;
          height: 36px;
          content: '';
          position: absolute;
          top: auto;
          left: auto;
          background-color: transparent;
          border: 3px solid ${buttonColor};
          border-radius: ${Spacing.S};
        }
      `}
    `
  }};

  &:hover {
    ${() => AppButton} {
      background-color: ${({ isClickable, buttonColor, hoverButtonColor }) => {
        return isClickable ? hoverButtonColor : buttonColor
      }};
    }
  }
`

type AppButtonProps = Omit<AppButtonWrapperProps, 'backgroundColor'>

export const AppButton = styled(Flex)<AppButtonProps>`
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: ${Spacing.XXS};

  &:hover {
    background-color: ${({ isClickable, buttonColor, hoverButtonColor }) => {
      return isClickable ? hoverButtonColor : buttonColor
    }};
  }

  ${({ buttonColor }) => {
    if (buttonColor) {
      return css`
        background-color: ${buttonColor};
      `
    }
  }};
`
