import { Button, ButtonsGroup } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useExternalInvoicesSyncModal } from '../../contexts/externalInvoicesSyncModalContext'

export const ModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { closeModal, isProcessing, submitForm } = useExternalInvoicesSyncModal()

  const handleSyncButtonClick = useCallback(() => {
    submitForm?.()
  }, [submitForm])

  const handleCancelButtonClick = useCallback(() => {
    closeModal()
  }, [closeModal])

  return (
    <ButtonsGroup>
      <Button disabled={isProcessing} onClick={handleCancelButtonClick} variant="text">
        {t('cancel')}
      </Button>
      <Button disabled={isProcessing} loading={isProcessing} onClick={handleSyncButtonClick}>
        {t('external_invoices_sync_modal.button.sync_now')}
      </Button>
    </ButtonsGroup>
  )
}
