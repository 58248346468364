import { BookkeepingTagNavItemValue } from '@components'

import { useCallback } from 'react'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useTaxRates } from '@modules-deprecated/app/taxrates'

import { BillFormFieldsChangeSource } from '../../../../../enums/billFormFieldsChangeSource'
import { useFormContext } from '../../../../../hooks/useForm'
import { useBillForm } from '../contexts/billFormContext'
import { autoCompleteBillForm } from '../utils/autoCompleteBillForm'
import { BillFormSchema } from '../utils/formData'
import { getBillFormFromBookkeepingTag } from '../utils/getBillFormFromBookkeepingTag'
import { getShouldOverridenParamChange } from '../utils/getShouldOverridenParamChange'
import { useTrackAutocompletedFields } from '../utils/trackAutocompletedFields'

export const useBookkeepingHeroAutoComplete = () => {
  const { organization } = useUserOrganization()
  const { getValues, reset: resetForm, setValue } = useFormContext<BillFormSchema>()
  const { accounts = [] } = useAccounts()
  const { taxRates = [] } = useTaxRates()
  const { trackAutocompletedFields } = useTrackAutocompletedFields()
  const { setFormFieldsChangeSource, formFieldsChangeSource } = useBillForm()

  const autoCompleteWithBookkeepingHeroData = useCallback(
    (
      bookkeepingTagValue: BookkeepingTagNavItemValue | undefined,
      lineIndex: number,
      source: BillFormFieldsChangeSource,
    ) => {
      const billForm = getValues()
      if (billForm.billLines) {
        billForm.billLines[lineIndex].lockedByBookkeepingTag = false
      }

      const billFormBookkeepingTag = getBillFormFromBookkeepingTag({
        accounts,
        bookkeepingTagValue,
        isVatExempted: organization?.isVatExempted,
        lineIndex,
        linesTotal: billForm.billLines?.length,
        taxRates,
        vendor: billForm.vendor,
      })

      const billFormUpdated = autoCompleteBillForm({
        billFormDefault: billForm,
        billFormOverrides: billFormBookkeepingTag,
        baseCurrencyId: organization?.baseCurrencyId,
        shouldOverridenParamChangeCallback: getShouldOverridenParamChange({ shouldChangeExpenseParams: true }),
      })

      if (billFormUpdated.billLines) {
        billFormUpdated.billLines[lineIndex].lockedByBookkeepingTag = !!bookkeepingTagValue
      }

      // bookkeeping tag having the highest priority = remove defaults when removing tag
      resetForm(billFormUpdated, { keepDefaultValues: !!bookkeepingTagValue })

      trackAutocompletedFields({
        billLines: billForm?.billLines?.[lineIndex],
        updatedBillLines: billFormUpdated?.billLines?.[lineIndex],
        context: source,
        formFieldsChangeSource,
        setFormFieldsChangeSource,
      })

      // Wait one frame before setting autoCompletedFields
      window.requestAnimationFrame(() => {
        setValue('autoCompletedFields', billFormUpdated.autoCompletedFields)
      })
    },
    [
      accounts,
      formFieldsChangeSource,
      getValues,
      organization?.isVatExempted,
      organization?.baseCurrencyId,
      resetForm,
      taxRates,
      trackAutocompletedFields,
      setFormFieldsChangeSource,
      setValue,
    ],
  )

  return { autoCompleteWithBookkeepingHeroData }
}
