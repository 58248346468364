import { notify } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Layout } from '@views/organizationInvitation/elements/Layout'
import { LoginForm } from '@views/organizationInvitation/elements/LoginForm'
import { SignupForm } from '@views/organizationInvitation/elements/SignupForm'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { SubmitLoginResponseData } from '../../../../query-api/login-query'
import { SubmitSignupResponseData } from '../../../../query-api/signup-query'
import { setAccessToken } from '../../../../utils'
import { useUmbrellaInvitation } from '../../context/umbrellaInvitationContext'
import { acceptUmbrellaInvitation } from '../../query-api'
import * as Styled from './styles'

interface AcceptUmbrellaInvitationProps {
  existingUser: boolean
  invitationEmail: string
  invitationToken: string
  umbrellaId: string
  umbrellaName: string
  isGrantAdmin: boolean
}

export const AcceptUmbrellaInvitation = ({
  existingUser,
  invitationEmail,
  invitationToken,
  umbrellaId,
  umbrellaName,
  isGrantAdmin,
}: AcceptUmbrellaInvitationProps): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { authorizeEmberUser } = useUmbrellaInvitation()

  const handleError = useCallback(() => {
    notify({ id: NotificationKeys.LoginError, message: t('error_saving'), variant: 'error' })
  }, [t])

  const acceptInvitationOnLogin = useCallback(
    async ({ meta: { accessToken } }: SubmitLoginResponseData) => {
      // Authorize the current user by access token
      setAccessToken(accessToken)
      authorizeEmberUser(accessToken)

      // Accept the invitation by invitation's token
      await acceptUmbrellaInvitation(invitationToken)

      // Redirect user based on their role
      if (isGrantAdmin) {
        navigate(EmberRoute.UmbrellaOrganizations, [umbrellaId])
      } else {
        navigate(EmberRoute.UmbrellaProfile, [umbrellaId])
      }
    },
    [invitationToken, umbrellaId, isGrantAdmin, authorizeEmberUser, navigate],
  )

  const acceptInvitationOnSignup = useCallback(
    async ({ meta: { accessToken } }: SubmitSignupResponseData) => {
      // Authorize the current user by access token
      setAccessToken(accessToken)
      authorizeEmberUser(accessToken)

      // Redirect user based on their role
      if (isGrantAdmin) {
        navigate(EmberRoute.UmbrellaOrganizations, [umbrellaId])
      } else {
        navigate(EmberRoute.UmbrellaProfile, [umbrellaId])
      }
    },
    [umbrellaId, isGrantAdmin, authorizeEmberUser, navigate],
  )

  return (
    <Layout withFooter>
      <Styled.AcceptInvitationFormWrapper>
        {existingUser ? (
          <LoginForm
            invitationEmail={invitationEmail}
            onSuccess={acceptInvitationOnLogin}
            onError={handleError}
            title={t('umbrella_invitation.form.title', { umbrellaName })}
          />
        ) : (
          <SignupForm
            invitationEmail={invitationEmail}
            invitationToken={invitationToken}
            onSuccess={acceptInvitationOnSignup}
            title={t('umbrella_invitation.form.title', { umbrellaName })}
          />
        )}
      </Styled.AcceptInvitationFormWrapper>
    </Layout>
  )
}
