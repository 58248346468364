import { TaxRate } from '../../modules-deprecated/app/taxrates/types'
import apiZervant from '../../utils/apiZervant'
import { ExternalInvoicesSyncJobStatus } from './enums/externalInvoicesSyncJobStatus'
import { ExternalInvoicesSyncStatus } from './enums/externalInvoicesSyncStatus'

export interface InitiateExternalInvoicesSyncPayload {
  companyId: string
  country: string
  externalOrganizationId: string
  jobStatus: ExternalInvoicesSyncJobStatus
  syncStartDate: string
}

export const initiateExternalInvoicesSync = async (payload: InitiateExternalInvoicesSyncPayload) => {
  return apiZervant.restInvoice.post('/v1/historical-sync/create-sync-status', payload)
}

export const updateExternalInvoicesSync = async (jobStatus: ExternalInvoicesSyncJobStatus) => {
  return apiZervant.restInvoice.patch('/v1/historical-sync/update-sync-status', { jobStatus })
}

interface FetchExternalInvoicesSyncResponse {
  processedDocuments: number | null
  syncStatus: ExternalInvoicesSyncStatus | null
  totalDocuments: number | null
}

export const fetchExternalInvoicesSync = async () => {
  return apiZervant.restInvoice.get<FetchExternalInvoicesSyncResponse>('/v1/historical-sync/sync-status')
}

interface FetchExternalUnrecognizedVatRatesResponse {
  taxRates: TaxRate[]
}

export const fetchExternalUnrecognizedVatRates = async () => {
  // this endpoint is used only temporarily, because the final endpoint is not ready yet,
  // it needs to be changed to '/v4/company/external-unrecognized-vat-rates'
  // before commercial launch of the feature
  return apiZervant.platform.get<FetchExternalUnrecognizedVatRatesResponse>('/v4/company/external-vat-rates')
}
