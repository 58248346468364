import { ScoutCard } from '@components'
import {
  Button,
  ButtonDropdown,
  Flex,
  IconButton,
  Link,
  ModalConfirmation,
  notify,
  SidePanel,
  Text,
  Tooltip,
  useModal,
} from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccounts } from '@modules-deprecated/app/accounts'

import { Gmail } from '../../../../assets/images'
import { ModalId } from '../../../../enums/modalId'
import { useSegment } from '../../../../hooks'
import { ScoutProvider } from '../../enums/scoutProvider'
import { useCreateScoutConsentUrl } from '../../hooks/useCreateScoutConsentUrl'
import { useDeleteScoutConnection } from '../../hooks/useDeleteScoutConnection'
import { useFetchScoutConnections } from '../../hooks/useFetchScoutConnections'
import { CreateScoutConsentUrlResponse } from '../../query-api'

enum ScoutActionId {
  DeleteConnection = 'delete_connection',
}

export const ReceiptsSidePanel = (): ReactElement => {
  const { t } = useTranslation()
  const { accounts } = useAccounts()
  const { connections } = useFetchScoutConnections()
  const { track } = useSegment()

  const googleEmailConnection = connections?.find((connection) => connection.provider === ScoutProvider.Google)

  const { create: createGoogleConsentUrl } = useCreateScoutConsentUrl({
    onSuccess: (response: CreateScoutConsentUrlResponse) => {
      window.open(response[ScoutProvider.Google], '_self')
    },
  })

  const { delete: deleteConnection, isProcessing: isDeleting } = useDeleteScoutConnection({
    onSuccess: () => {
      notify({
        id: 'connection-deleted',
        message: t('receipts.side_panel.google_email.remove.succeeded'),
        variant: 'success',
      })
      closeDeleteConfirmationModal()
    },
    onError: () => {
      notify({
        id: 'connection-deleted',
        message: t('receipts.side_panel.google_email.remove.failed'),
        variant: 'error',
      })
    },
  })

  const { open: openDeleteConfirmationModal, close: closeDeleteConfirmationModal } = useModal(
    ModalId.ScoutConnectionDeletionModal,
  )

  const handleConnnectButtonClick = useCallback(async () => {
    track('Scout Connect Clicked (FE)', {
      type: ScoutProvider.Google,
    })
    if (!googleEmailConnection) {
      accounts && createGoogleConsentUrl(accounts?.map((account) => account.id))
    }
  }, [accounts, createGoogleConsentUrl, googleEmailConnection, track])

  const handleSideActionItemSelect = useCallback(
    (value: string) => {
      switch (value) {
        case ScoutActionId.DeleteConnection:
          track('Scout Disconnect Clicked (FE)', {
            type: ScoutProvider.Google,
          })
          openDeleteConfirmationModal()
          break
      }
    },
    [openDeleteConfirmationModal, track],
  )
  const sideActionItems = [
    {
      id: ScoutActionId.DeleteConnection,
      children: t('receipts.side_panel.disconnect'),
      value: ScoutActionId.DeleteConnection,
    },
  ]

  const handleDeleteModalOkClick = useCallback(() => {
    googleEmailConnection && deleteConnection(googleEmailConnection.id)
  }, [deleteConnection, googleEmailConnection])

  return (
    <>
      <SidePanel>
        <SidePanel.Header title={t('receipts.side_panel.title')} />
        <SidePanel.Body>
          <Flex alignItems="center">
            <Text variant="h3" weight="bold">
              {t('receipts.side_panel.receipt_finder.title')}
            </Text>
            <Tooltip label={t('receipts.side_panel.tooltip')} placement="left">
              <IconButton icon="exclamationMarkCircle" />
            </Tooltip>
          </Flex>
          {googleEmailConnection && (
            <Text variant="h4" colorVariant="secondary">
              {t('receipts.side_panel.receipt_finder.status.active')}
            </Text>
          )}
          <ScoutCard>
            <ScoutCard.Header
              title={t('receipts.side_panel.google_email.title')}
              subtitle={googleEmailConnection?.email ?? t('receipts.side_panel.google_email.description')}
              image={<Gmail />}
              sideActions={
                googleEmailConnection && (
                  <ButtonDropdown
                    items={sideActionItems}
                    placement="bottom-end"
                    triggerElement={<Button variant="text" icon="threeDots" />}
                    onSelect={handleSideActionItemSelect}
                  />
                )
              }
            />
            {!googleEmailConnection && <ScoutCard.Divider />}
            {!googleEmailConnection && (
              <ScoutCard.Footer>
                <Link href="https://www.google.com" target="__blank">
                  {t('receipts.side_panel.learn_more')}
                </Link>
                <Button
                  variant="secondary"
                  icon={googleEmailConnection ? 'checkCircle' : undefined}
                  onClick={handleConnnectButtonClick}
                >
                  {t('receipts.side_panel.connect')}
                </Button>
              </ScoutCard.Footer>
            )}
          </ScoutCard>
        </SidePanel.Body>
      </SidePanel>
      {/* Delete Confirmation Modal  */}
      <ModalConfirmation
        cancelLabel={t('cancel')}
        danger
        id={ModalId.ScoutConnectionDeletionModal}
        message={t('receipts.side_panel.google_email.remove.description')}
        okLabel={t('yes')}
        okLoading={isDeleting}
        onOk={handleDeleteModalOkClick}
        title={t('receipts.side_panel.google_email.remove.title')}
      />
    </>
  )
}
