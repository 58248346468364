import { ProtectedComponent } from '@components'
import { Button, ButtonsGroup, SectionMessage, Space, Text } from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { useAccountGroupsWithSums, useAccounts } from '@modules-deprecated/app/accounts'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { QueryKeys } from '../../../../enums/queryKeys'
import { Scope } from '../../../../enums/scope'
import { SettingsModuleLayout } from '../../elements/SettingsModuleLayout'
import { SettingsRoute } from '../../enums/settingsRoute'
import { useChartOfAccounts } from './contexts/chartOfAccountsContext'
import { ChartOfAccountsList } from './elements/ChartOfAccountsList'
import { ChartOfAccountsNavigator } from './elements/ChartOfAccountsNavigator'
import { EditAccountModal } from './elements/EditAccountModal'
import { useChartOfAccountsFilters } from './hooks/useChartOfAccountsFilters'
import * as Styled from './styles'
import { AccountsGroupsSet } from './types'
import { filterAccounts } from './utils/filterAccounts'
import { getAccountsCount } from './utils/getAccountsCount'
import { getCoaCodeFromId } from './utils/getCoaCodeFromId'
import { groupAccountsByGroupId } from './utils/groupAccountsByGroupId'
import { sortAccountGroups } from './utils/sortAccountGroups'

export const ChartOfAccounts = (): ReactElement => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false)
  const { accounts = [], isLoading: isAccountsLoading } = useAccounts()
  const { accountGroups = [], isLoading: isGroupsLoading } = useAccountGroupsWithSums()
  const { organization } = useUserOrganization()
  const { navigate } = useEmberRouter()
  const [{ noPublicCoaId }, setQueryParams] = useChartOfAccountsFilters()
  const { onReloadAccounts } = useChartOfAccounts()

  const [includeArchived, setIncludeArchived] = useState(false)
  const [searchString, setSearchString] = useState('')
  const coaCode = getCoaCodeFromId(organization?.chartOfAccountId)

  const accountGroupsSet: AccountsGroupsSet = useMemo(
    () => ({
      accountsInGroups: groupAccountsByGroupId(accounts),
      groups: sortAccountGroups(accountGroups),
    }),
    [accounts, accountGroups],
  )

  const accountsFiltered = useMemo(
    () => filterAccounts(accountGroupsSet, includeArchived, searchString, !!noPublicCoaId),
    [accountGroupsSet, includeArchived, searchString, noPublicCoaId],
  )

  const accountsWithoutPublicCoaId = useMemo(() => {
    return accounts.filter((account) => !account.publicAccountId)
  }, [accounts])

  const accountsCount = getAccountsCount(accountsFiltered)

  const handleModalClose = useCallback(() => {
    setIsAddAccountModalOpen(false)
  }, [])

  const handleIncludeArchivedChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIncludeArchived(event.target.checked)
  }, [])

  const handleAccountSaved = useCallback(() => {
    queryClient.invalidateQueries(QueryKeys.Accounts)
    onReloadAccounts({ detail: null })
  }, [onReloadAccounts, queryClient])

  const handleCreateAccountButtonClick = useCallback(() => {
    setIsAddAccountModalOpen(true)
  }, [])

  const handleImportClick = useCallback(() => {
    navigate(EmberRoute.AccountsImport)
  }, [navigate])

  const handleFilterClick = useCallback(() => {
    setQueryParams({
      noPublicCoaId: true,
    })
  }, [setQueryParams])

  return (
    <SettingsModuleLayout
      title={t('coa.chart_of_accounts')}
      withBackButton
      backButtonTo={SettingsRoute.OrganizationAccounting}
      sideActions={
        <ButtonsGroup>
          <ProtectedComponent scopes={Scope.ChartOfAccountsImportWrite}>
            <Button variant="secondary" onClick={handleImportClick}>
              {t('coa.import_chart_of_accounts')}
            </Button>
          </ProtectedComponent>
          <Button variant="primary" onClick={handleCreateAccountButtonClick}>
            {t('coa.create_account')}
          </Button>
        </ButtonsGroup>
      }
    >
      {coaCode !== 'none' && (
        <>
          {!!accountsWithoutPublicCoaId.length && (
            <Styled.SectionMessageWrapper>
              <SectionMessage variant="error" title={t(`coa.warning.missing_public_coa.title`)}>
                <Text inherit>{t(`coa.warning.missing_public_coa.text`)}</Text>
                <Space size="m" />
                <Button size="m" variant="secondary" onClick={handleFilterClick}>
                  {t(`coa.warning.missing_public_coa.action_button_label`)}
                </Button>
              </SectionMessage>
            </Styled.SectionMessageWrapper>
          )}

          <Styled.SectionMessageWrapper>
            <SectionMessage variant="error">
              <Text weight="medium" inherit>
                {t(`coa.warning.${coaCode}.intro`)}
              </Text>
              <Styled.WarningList>
                <Styled.WarningListItem key={`${coaCode}.a`}>{t(`coa.warning.${coaCode}.a`)}</Styled.WarningListItem>
                <Styled.WarningListItem key={`${coaCode}.b`}>{t(`coa.warning.${coaCode}.b`)}</Styled.WarningListItem>
                <Styled.WarningListItem key={`${coaCode}.c`}>{t(`coa.warning.${coaCode}.c`)}</Styled.WarningListItem>
              </Styled.WarningList>
            </SectionMessage>
          </Styled.SectionMessageWrapper>
        </>
      )}
      <ChartOfAccountsNavigator
        accountsCount={accountsCount}
        onArchivedChange={handleIncludeArchivedChange}
        onQueryChange={setSearchString}
      />
      <ChartOfAccountsList
        accountGroupsSet={accountsFiltered}
        isLoading={isAccountsLoading || isGroupsLoading}
        onReloadAccounts={onReloadAccounts}
      />
      <EditAccountModal isOpen={isAddAccountModalOpen} onClose={handleModalClose} onSave={handleAccountSaved} />
    </SettingsModuleLayout>
  )
}
