import { notify } from '@design-system'

import { format, isAfter, isBefore, subDays } from 'date-fns'
import i18next from 'i18next'
import _ from 'lodash'
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'

import { Contact } from '@views/contacts/types/contact'

import { NotificationKeys } from '../../enums/notificationKeys'
import { SpecificState } from '../../types/reduxSaga-deprecated'
import { APIError, deleteRequest, getRequest, postRequest, putRequest, wait } from '../../utils'
import { Account } from '../app/accounts/types'
import { Organization } from '../app/organization/types/organization'
import {
  bankBalanceReceived,
  bankConnectionReceived,
  bankConnectionRequestFailed,
  bankLineLatestReceived,
  bankLineMatchesDeleted,
  bankLineMatchRemoved,
  bankLineMatchUpdated,
  bankLinesAndTransactionsReceived,
  bankLinesLoading,
  bankLineSubjectAssociationCreated,
  bankLineSubjectAssociationDeleted,
  bankLineSubjectAssociationUpdated,
  bankLineUngrouped,
  billyTransactionAdded,
  bulkReconcileStateUpdated,
  differenceTypeSelected,
  reconcileAllApprovedStateUpdated,
  transactionsLoading,
} from './action-creators'
import {
  BANK_BALANCE_REQUESTED,
  BANK_CONNECTION_REQUESTED,
  BANK_LINE_SUBJECT_ASSOCIATION_DELETE_REQUESTED,
  BANK_LINES_AND_TRANSACTIONS_REQUESTED,
  BANK_LINES_LATEST_LINE_REQUESTED,
  BULK_RECONCILE,
  CREATE_BILL_AND_RECONCILE,
  CREATE_INVOICE_AND_RECONCILE,
  DELETE_BANK_LINES,
  DND_BANK_LINES,
  DND_BILLY_TRANSACTIONS,
  RECONCILE_ALL,
  RECONCILE_MATCH,
  SELECT_DIFFERENCE_TYPE,
  UNGROUP_BANK_LINE,
  UNRECONCILE_MATCH,
} from './actions'
import {
  ApprovedMatchResult,
  BankConnection,
  BankConnectionRequested,
  BankConnectionSession,
  BankLine,
  BankLineMatch,
  BankLineSubjectAssociation,
  Bill,
  BillyTransaction,
  CreateBillAndReconcileAction,
  CreateInvoiceAndReconcileAction,
  DeleteBankLinesAction,
  DeleteBankLineSubjectAssociationAction,
  DifferenceType,
  ExtendedBankLineMatch,
  Filters,
  GetBankLineMatchesResponse,
  GetPostingsResponse,
  Invoice,
  Posting,
  ReconcilablePostingType,
  ReconcileMatchAction,
  UnreconcileMatchAction,
} from './types'
import {
  cleanId,
  createBillyTransactionFromBill,
  createBillyTransactionFromInvoice,
  createBillyTransactionFromPosting,
  formatDateRangePeriod,
  isReconcilable,
  makeBankLines,
  makeMatchedBillyTransactions,
} from './utils'

export type FiltersWithParsedDateRange = Filters & { fromDate: string; toDate: string }

// ---------------------------------
// Connect
// ---------------------------------

function* fetchBankConnection({ payload: { organizationId, accountId } }: BankConnectionRequested) {
  try {
    const bankConnection: BankConnection = yield call(
      getRequest,
      `/organizations/${organizationId}/accounts/${accountId}/bankConnection`,
    )
    const sessions: any[] = yield call(getRequest, `/spiir/organizations/${organizationId}/sessions`)
    if (bankConnection && sessions) {
      const session = sessions.find((session: BankConnectionSession) => session.id === bankConnection.referenceId)
      if (session) {
        bankConnection.session = session
      }
    }

    yield put(bankConnectionReceived(bankConnection))
  } catch (e: any) {
    if (e instanceof APIError && e.statusCode === 404) {
      yield put(bankConnectionReceived(null))
      return
    }

    yield put(bankConnectionRequestFailed(e))
  }
}

// ---------------------------------
// Fetch all data
// ---------------------------------

function* fetchBankLinesAndTransactions(payload = { silent: false }): any {
  const account: Account = yield select((state) => state.app.account)
  const organization: Organization = yield select((state) => state.app.organization)
  const allFilters = yield select((state) => state.bankReconciliation.filters)
  const { bankLineGroupFilters, dateRange, fiscalYear, reconcilablePostingFilters } = allFilters

  if (!organization || !account?.id || dateRange.length < 2) {
    return
  }

  const { from: fromDate, to: toDate } = formatDateRangePeriod(fiscalYear, organization.fiscalYearEndMonth, dateRange)

  const bankLineMatches: ExtendedBankLineMatch[] = []
  const bankLineSubjectAssociations: BankLineSubjectAssociation[] = []
  const billyTransactions: BillyTransaction[] = []

  const shouldRetrievePostings = bankLineGroupFilters.showReconciled
  const shouldRetrieveBankLineMatches = bankLineGroupFilters.showManual || bankLineGroupFilters.showMatched

  if (!payload.silent) {
    // Enable loading state
    yield put(transactionsLoading({ isLoading: true }))

    if (shouldRetrieveBankLineMatches) {
      yield put(bankLinesLoading({ isLoading: true }))
    }
  }

  // There is an inconsistency between the IDs and types returned by the bankLines and the reconcilablePosting API.
  // One will, for example, return a reference of type posting:<id> whereas the other will return bill:<id>. We therefore
  // need to manually extract matched postings from the API ourselves and then add that to the allBillyTransactions which
  // is used to populate the matchedBillyTransactions in the bankLineMatches.
  const [allBillyTransactions, postings, bankLineMatchInformation] = yield all([
    // We need to throttle this call because the API constantly crashes when trying these three calls simultaneously.
    // The throttling is still faster than doing one after the other though.
    call(getAllBillyTransactions, organization.id, account.id),
    shouldRetrievePostings ? call(getPostings, account.id, fromDate, toDate) : [],
    bankLineGroupFilters.showManual || bankLineGroupFilters.showMatched
      ? getBankLineMatchInformation(account, { fromDate, toDate, ...allFilters })
      : { bankLines: [], bankLineMatches: [], bankLineSubjectAssociations: [] },
  ])

  if (shouldRetrievePostings) {
    allBillyTransactions.push(...postings.map(createBillyTransactionFromPosting))
  }

  if (shouldRetrieveBankLineMatches) {
    bankLineMatches.push(
      ...bankLineMatchInformation.bankLineMatches.map(
        (match: BankLineMatch): ExtendedBankLineMatch => ({
          ...match,
          bankLines: makeBankLines(match, bankLineMatchInformation), // populate each match with its bank lines
          matchedBillyTransactions: makeMatchedBillyTransactions(match, bankLineMatchInformation, allBillyTransactions), // populate each match with its billy transactions
        }),
      ),
    )
    if (bankLineMatchInformation.bankLineSubjectAssociations) {
      bankLineSubjectAssociations.push(...bankLineMatchInformation.bankLineSubjectAssociations)
    }
  }

  if (reconcilablePostingFilters.types.length) {
    const filteredBillyTransactions = filterBillyTransactions(allBillyTransactions, {
      filters: allFilters,
      fromDate,
      toDate,
    })

    // We need to filter out billyTransactions that are marked as matched but cannot be found in any subject association
    // The reason for this is that some of the billyTransactions returned from the reconcilablePostings API are of type
    // bill and invoice but appear as "posting" in the bankLines API. For this reason, we use the additional postings we
    // have retrieved and appended to allBillyTransactions to fill out the missing information
    billyTransactions.push(
      ...filteredBillyTransactions.filter((billyTransaction) => {
        return (
          !billyTransaction.isBankMatched ||
          !!bankLineSubjectAssociations.find((blsa) => blsa.subjectReference.endsWith(billyTransaction.id))
        )
      }),
    )
  }

  yield put(
    bankLinesAndTransactionsReceived({
      bankLineMatches,
      billyTransactions,
      bankLineSubjectAssociations,
    }),
  )

  // Disable loading state
  yield put(bankLinesLoading({ isLoading: false }))
  yield put(transactionsLoading({ isLoading: false }))
}

function* fetchLatestBankLine() {
  const account: Account = yield select((state) => state.app.account)
  const organization: Organization = yield select((state) => state.app.organization)

  if (!organization || !account?.id) {
    return
  }

  const params = new URLSearchParams()
  params.append('accountId', account.id)
  params.append('sortProperty', 'entryDate')
  params.append('sortDirection', 'DESC')
  params.append('include', 'bankLineMatch.lines')
  params.append('pageSize', '1')

  const res: GetBankLineMatchesResponse = yield call(getRequest, `/v2/bankLineMatches?${params.toString()}`)
  const latestBankLineEntryDate = res.bankLines ? res.bankLines[0]?.entryDate || undefined : undefined

  yield put(bankLineLatestReceived({ latestBankLineEntryDate }))
}

export function* getPostings(accountId: string, fromDate: string, toDate: string) {
  const postingInformation: Omit<GetPostingsResponse, 'meta'> = {
    postings: [],
    transactions: [],
  }

  let retrievalUrl = `/v2/postings?${[
    `accountId=${accountId}`,
    'isVoided=0',
    'isBankMatched=1',
    `entryDatePeriod=dates:${fromDate}...${toDate}`,
    'include=posting.transaction,transaction.originator',
  ].join('&')}`

  while (true) {
    const response: GetPostingsResponse = yield call(getRequest, retrievalUrl)
    postingInformation.postings.push(...response.postings)
    postingInformation.transactions.push(...response.transactions)

    if (!response.meta.paging.nextUrl || response.meta.paging.page === response.meta.paging.pageCount) {
      break
    }

    retrievalUrl = response.meta.paging.nextUrl
  }

  return postingInformation.postings.map((posting) => {
    const transaction = postingInformation.transactions.find((transaction) => transaction.id === posting.transactionId)
    return {
      ...transaction, // We do this on purpose to delete the transaction.id so it does not interfere with posting.id
      ...posting,
    }
  })
}

// ---------------------------------
// Drag and drop
// ---------------------------------

type DNDProps = {
  payload: {
    id: string
    matchId: string
    sourceMatchId: string
    billyTransaction?: BillyTransaction
    bankLineSubjectAssociation?: BankLineSubjectAssociation
  }
  type: string
  cb?: (err: string | null) => void
}

function* dndBankLines({ payload: { id, matchId } }: DNDProps) {
  const [, rawMatchId] = cleanId(matchId)
  try {
    yield call(putRequest, `/v2/bankLines/${id}`, { bankLine: { id, matchId: rawMatchId } })
  } catch (e) {
    // Something went wrong, fetch everything again
    yield fetchBankLinesAndTransactions({ silent: true })
  }
}

function* dndBillyTransactions({ payload }: DNDProps) {
  const { id, matchId, sourceMatchId, billyTransaction, bankLineSubjectAssociation } = payload
  const [, rawMatchId] = cleanId(matchId)
  const [, rawSourceMatchId] = cleanId(sourceMatchId)

  try {
    if (rawMatchId === 'source' && bankLineSubjectAssociation) {
      yield call(deleteRequest, `/v2/bankLineSubjectAssociations/${bankLineSubjectAssociation.id}`)
      yield put(bankLineSubjectAssociationDeleted(bankLineSubjectAssociation.id))
    } else if (rawSourceMatchId === 'source' && billyTransaction) {
      const { bankLineSubjectAssociations } = yield call(postRequest, '/v2/bankLineSubjectAssociations', {
        bankLineSubjectAssociation: {
          matchId: rawMatchId,
          subjectReference: `${billyTransaction.subtype}:${id}`,
        },
      })
      yield put(bankLineSubjectAssociationCreated(bankLineSubjectAssociations[0]))
    } else {
      if (bankLineSubjectAssociation) {
        const { bankLineSubjectAssociations } = yield call(
          putRequest,
          `/v2/bankLineSubjectAssociations/${bankLineSubjectAssociation.id}`,
          {
            bankLineSubjectAssociation: {
              matchId: rawMatchId,
            },
          },
        )
        yield put(bankLineSubjectAssociationUpdated(bankLineSubjectAssociations[0]))
      } else {
        yield fetchBankLinesAndTransactions({ silent: true })
      }
    }
  } catch (e) {
    // Something went wrong, fetch everything again
    yield fetchBankLinesAndTransactions({ silent: true })
  }
}

// ---------------------------------
// Group / ungroup
// ---------------------------------
type UngroupBankLineProps = {
  payload: {
    accountId: string
    amount: number
    cb: (err: string | null) => void
    entryDate: string
    isApproved: boolean
    lineId: string
    matchId: string
    side: string
  }
  type: string
}

function* ungroupBankLine({ payload }: UngroupBankLineProps) {
  const { accountId, lineId, matchId, amount, entryDate, isApproved, side, cb } = payload
  try {
    const res: { bankLineMatches: any[] } = yield call(postRequest, '/v2/bankLineMatches', {
      bankLineMatch: {
        accountId,
        amount,
        entryDate,
        isApproved,
        side,
      },
    })
    const newBankLineMatchId = res.bankLineMatches[0].id
    const res2: { bankLines: any[] } = yield call(putRequest, `/v2/bankLines/${lineId}`, {
      bankLine: {
        id: lineId,
        matchId: newBankLineMatchId,
      },
    })
    const newBankLineMatch = res.bankLineMatches[0]
    const ungroupedBankLine = res2.bankLines[0]
    yield put(
      bankLineUngrouped({
        newBankLineMatch,
        ungroupedBankLine,
        previousBankLineMatchId: matchId,
      }),
    )
    cb(null)
  } catch (e) {
    if (e instanceof APIError && e.statusCode === 422) {
      cb(e.body)
    }
    throw e
  }
}

// ---------------------------------
// Balance bar
// ---------------------------------

function* bankBalanceRequested(): unknown {
  try {
    const account: Account = yield select((state) => state.app.account)
    const fiscalYearEndMonth = yield select((state) => state.app.organization.fiscalYearEndMonth)
    const { fiscalYear, dateRange } = yield select((state) => state.bankReconciliation.filters)

    if (dateRange.length < 2) {
      return
    }

    const { from: fromDate, to: toDate } = formatDateRangePeriod(fiscalYear, fiscalYearEndMonth, dateRange)
    const datePeriodString = `${fromDate}...${toDate}`
    const fromDateMinusOneDay = format(subDays(new Date(fromDate), 1), 'yyyy-MM-dd')

    const bankMatchesQueryParameters = [
      `accountId=${account.id}`,
      `entryDatePeriod=dates%3A${datePeriodString}`,
      'sortProperty=entryDate',
      'include=bankLineMatch.lines',
      'pageSize=1',
    ]

    const [bankEndBalance, bankStartBalance, billyEndBalance, billyStartBalance] = yield all([
      call(getRequest, `/v2/bankLines?${bankMatchesQueryParameters.join('&')}&sortDirection=DESC`),
      call(getRequest, `/v2/bankLines?${bankMatchesQueryParameters.join('&')}&sortDirection=ASC`),
      call(getRequest, `/v2/accounts/${account.id}/balance?currencyId=DKK&endDate=${toDate}`),
      call(getRequest, `/v2/accounts/${account.id}/balance?currencyId=DKK&endDate=${fromDateMinusOneDay}`),
    ])

    const primoUltimoBalance = {
      bankEndBalance: bankEndBalance.bankLines[0]?.balance || 0,
      bankStartBalance: (bankStartBalance.bankLines[0]?.balance || 0) - (bankStartBalance.bankLines[0]?.amount || 0),
      billyEndBalance: billyEndBalance?.balance || 0,
      billyStartBalance: billyStartBalance?.balance || 0,
    }

    yield put(bankBalanceReceived({ primoUltimoBalance }))

    // Error handler
  } catch (e: any) {
    if (e.statusCode === 404) {
      yield put(bankBalanceReceived({ primoUltimoBalance: null }))
    }
  }
}

// ---------------------------------
// Difference types
// ---------------------------------

type SelectDifferenceTypeProps = {
  payload: {
    type: DifferenceType
    matchId: string
  }
  type: string
}

function* selectDifferenceType({ payload }: SelectDifferenceTypeProps) {
  const { type, matchId } = payload
  const organization: { defaultBankFeeAccountId: any } = yield select((state) => state.app.organization)
  const defaultBankFeeAccountId = organization.defaultBankFeeAccountId

  try {
    yield call(putRequest, `/v2/bankLineMatches/${matchId}`, {
      bankLineMatch: {
        differenceType: type,
        id: matchId,
        ...(type === 'bankFee' && { feeAccountId: defaultBankFeeAccountId }),
      },
    })
    yield put(differenceTypeSelected({ matchId, type }))
  } catch (e) {
    if (e instanceof APIError && e.statusCode === 422) {
      // @TODO: Why was this empty if added here? Are we meant to prevent throwing, or should it be removed?
    }

    throw e
  }
}

// --------------------------------------
// Reconciliation
// --------------------------------------

function* reconcileMatch({ payload: matchId }: ReconcileMatchAction) {
  try {
    yield call(putRequest, `/v2/bankLineMatches/${matchId}`, { bankLineMatch: { isApproved: true } })
    notify({
      id: NotificationKeys.BankReconciliationMatchReconcile,
      message: i18next.t('bankreconciliation.reconcile.success'),
      variant: 'success',
    })
    const showReconciled: boolean = yield select((state: SpecificState) => {
      return state.bankReconciliation.filters.bankLineGroupFilters.showReconciled
    })

    if (showReconciled) {
      yield call(updateBankLineMatch, matchId, { isApproved: true })
    } else {
      yield put(bankLineMatchRemoved(matchId))
    }
  } catch (e) {
    if (e instanceof APIError && e.statusCode === 422) {
      notify({
        id: NotificationKeys.BankReconciliationMatchReconcile,
        message: e.body?.errorMessage || i18next.t('bankreconciliation.reconcile.validation_failure'),
        variant: 'error',
      })
      return
    }

    throw e
  }
}

function* unreconcileMatch({ payload: matchId }: UnreconcileMatchAction) {
  try {
    yield call(putRequest, `/v2/bankLineMatches/${matchId}`, { bankLineMatch: { isApproved: false } })
    notify({
      id: NotificationKeys.BankReconciliationMatchUnreconcile,
      message: i18next.t('bankreconciliation.unreconcile.success'),
      variant: 'success',
    })

    yield call(updateBankLineMatch, matchId, { isApproved: false })
  } catch (e) {
    if (e instanceof APIError && e.statusCode === 422) {
      notify({
        id: NotificationKeys.BankReconciliationMatchUnreconcile,
        message: i18next.t('bankreconciliation.unreconcile.failure'),
        variant: 'error',
      })
      return
    }

    throw e
  }
}

type BulkReconcileProps = {
  payload: {
    [key: string]: {
      accountId: string
      amount: number
      contraAccountId: string
      side: 'credit' | 'debit'
      taxRateId: string
      text: string
    }
  }
  type: string
}

type Transaction = {
  accountId: string
  amount: number
  contraAccountId: string
  side: 'debit' | 'credit'
  taxRateId: string
  text: string
}

function* bulkCreateAndReconcile({ payload }: BulkReconcileProps) {
  const { bulkReconcile } = payload
  const { id: organizationId }: Organization = yield select((state) => state.app.organization)
  const reconcilables = Object.keys(bulkReconcile).map((key) => ({
    ...bulkReconcile[key],
    matchId: key,
  }))

  const daybookTransaction = {
    attachments: [],
    organizationId,
    state: 'approved',
    type: 'entry',
  }

  const successfullyReconciled = [] as boolean[]

  for (let i = 0; i < reconcilables.length; i++) {
    let success
    const transaction = reconcilables[i]
    const matchId = transaction.matchId
    try {
      const { nextVoucherNo } = yield call(getRequest, `/v2/organizations/${organizationId}/nextVoucherNo`)
      const entryDate = transaction.entryDate
      delete transaction.matchId
      delete transaction.entryDate
      const requestBody = {
        daybookTransaction: {
          ...daybookTransaction,
          entryDate,
          lines: [transaction as Transaction],
          voucherNo: nextVoucherNo.toString(),
        },
      }
      const createTransactionResult: { postings: any[]; meta: any } = yield call(
        postRequest,
        '/v2/daybookTransactions',
        requestBody,
      )
      const posting = createTransactionResult.postings.find(
        (item: Posting) => item.accountId === transaction.contraAccountId,
      )
      yield call(postRequest, '/v2/bankLineSubjectAssociations', {
        bankLineSubjectAssociation: {
          matchId,
          subjectReference: `posting:${posting.id}`,
        },
      })
      const reconciliationResult: { meta: any } = yield call(putRequest, `/v2/bankLineMatches/${matchId}`, {
        bankLineMatch: { isApproved: true },
      })
      success = createTransactionResult.meta.success && reconciliationResult.meta.success
      successfullyReconciled.push(success)
    } catch (e) {
      console.error(e)
      return null
    }
    yield wait(500)
    yield put(bulkReconcileStateUpdated({ matchId, success }))
  }
}

function* reconcileAllApprovedMatches() {
  const bankLineMatches: any[] = yield select((state) => state.bankReconciliation.bankLineMatches)
  const reconcilable = bankLineMatches.filter(isReconcilable)

  const recentlyApprovedMatches: ApprovedMatchResult[] = []

  // Do nothing if there is no reconcilable matches
  if (!reconcilable.length) {
    notify({
      id: NotificationKeys.BankReconciliationAllApprovedMatchesReconcile,
      message: i18next.t('bankreconciliation.reconcileallmodal.responsemessage', { reconciled: 0, total: 0 }),
      variant: 'error',
    })
    return
  }

  const showReconciled: boolean = yield select((state: SpecificState) => {
    return state.bankReconciliation.filters.bankLineGroupFilters.showReconciled
  })

  try {
    let processedItems = 0

    yield put(reconcileAllApprovedStateUpdated({ reconciling: true, total: reconcilable.length, processed: 0 }))
    yield all(
      reconcilable.map((match: ExtendedBankLineMatch) =>
        call(function* () {
          let finishedProcessingItem = false
          try {
            let attempts = 0
            let bankLineMatch
            while (true) {
              try {
                const { bankLineMatches } = yield call(putRequest, `/v2/bankLineMatches/${match.id}`, {
                  bankLineMatch: { isApproved: true },
                })

                bankLineMatch = bankLineMatches[0]
                finishedProcessingItem = true
                break
              } catch (e) {
                if (attempts++ >= 5) {
                  finishedProcessingItem = true
                  throw e
                }

                yield delay(1000)
              }
            }

            recentlyApprovedMatches.push({
              id: match.id,
              isApproved: bankLineMatch?.isApproved,
            })

            if (showReconciled) {
              yield call(updateBankLineMatch, match.id, { isApproved: true })
            } else {
              yield put(bankLineMatchRemoved(match.id))
            }
          } catch (e) {
            console.warn(`Match ${match.id} could not be approved:`, e)
          } finally {
            yield put(
              reconcileAllApprovedStateUpdated({
                reconciling: true,
                total: reconcilable.length,
                processed: finishedProcessingItem ? ++processedItems : processedItems,
                successful: recentlyApprovedMatches.length,
              }),
            )
          }
        }),
      ),
    )
  } catch (e) {
    if (e instanceof APIError && e.statusCode === 422) {
      // @TODO: We should add a proper error message here
    }

    throw e
  } finally {
    yield put(reconcileAllApprovedStateUpdated({ reconciling: false }))
  }

  const reconciled = recentlyApprovedMatches?.length || 0
  const total = reconcilable.length
  const variant = reconciled !== total || reconciled === 0 ? 'error' : 'success'
  notify({
    id: NotificationKeys.BankReconciliationAllApprovedMatchesReconcile,
    message: i18next.t('bankreconciliation.reconcileallmodal.responsemessage', { reconciled, total }),
    variant,
  })
}

function* deleteBankLineSubjectAssociation({
  payload: { bankLineSubjectAssociation, bankLineMatch },
}: DeleteBankLineSubjectAssociationAction) {
  // Optimistic update of the view
  yield put(bankLineSubjectAssociationDeleted(bankLineSubjectAssociation.id))

  const [, subjectId] = cleanId(bankLineSubjectAssociation.subjectReference)
  const [transaction] =
    bankLineMatch.matchedBillyTransactions.filter((billyTransaction: BillyTransaction) => {
      return billyTransaction.id === subjectId
    }) || []

  yield put(
    bankLineMatchUpdated({
      ...bankLineMatch,
      subjectAssociationIds: bankLineMatch.subjectAssociationIds.filter((blsaId: string) => {
        return blsaId !== bankLineSubjectAssociation.id
      }),
      matchedBillyTransactions: bankLineMatch.matchedBillyTransactions.filter((billyTransaction: BillyTransaction) => {
        return billyTransaction.id !== subjectId
      }),
    }),
  )

  if (transaction) {
    yield put(billyTransactionAdded(transaction))
  }

  // Actual API call
  try {
    yield call(deleteRequest, `/v2/bankLineSubjectAssociations/${bankLineSubjectAssociation.id}`)
  } catch (e) {
    // Revert if the call fails
    yield fetchBankLinesAndTransactions({ silent: true })
  }
}

function* createInvoiceAndReconcile({ payload }: CreateInvoiceAndReconcileAction) {
  const { bankLineMatch, organizationId, currencyId, contactId, productId, description } = payload

  const lineAmount = bankLineMatch.bankLines.reduce((total: number, line: BankLine) => {
    return total + (line.side === 'debit' ? 1 : -1) * (line.amount || 0)
  }, 0)

  try {
    const {
      invoices: [invoice],
    } = yield call(postRequest, '/v2/invoices', {
      invoice: {
        organizationId,
        currencyId,
        contactId,
        entryDate: bankLineMatch.entryDate,
        state: 'approved',
        taxMode: 'incl',
        lines: [
          {
            quantity: 1,
            unitPrice: lineAmount,
            productId,
            description,
          },
        ],
      },
    })

    yield call(processInvoiceOrBillAndReconcile, bankLineMatch, invoice)
    notify({
      id: NotificationKeys.BankReconciliationTransactionCreatedAndReconcile,
      message: i18next.t('bankreconciliation.create_invoice_bill.created_and_reconciled_success'),
      variant: 'success',
    })
  } catch (e) {
    notify({
      id: NotificationKeys.BankReconciliationTransactionCreatedAndReconcile,
      message: i18next.t('bankreconciliation.create_invoice_bill.created_and_reconciled_failure'),
      variant: 'error',
    })
  } finally {
    // @TODO: This should add the individual invoice and match, not fetch the entire thing
    yield fetchBankLinesAndTransactions({ silent: true })
  }
}

function* createBillAndReconcile({ payload }: CreateBillAndReconcileAction) {
  const { bankLineMatch, organizationId, currencyId, contactId, accountId, taxRateId, voucherNo, description } = payload

  const lineAmount = bankLineMatch.bankLines.reduce((total: number, line: BankLine) => {
    return total + (line.side === 'debit' ? 1 : -1) * (line.amount || 0)
  }, 0)

  try {
    const {
      bills: [bill],
    } = yield call(postRequest, '/v2/bills', {
      bill: {
        organizationId,
        currencyId,
        contactId,
        entryDate: bankLineMatch.entryDate,
        state: 'approved',
        taxMode: 'incl',
        origin: 'web',
        voucherNo,
        lines: [
          {
            accountId,
            amount: -1 * lineAmount,
            taxRateId,
            description,
          },
        ],
      },
    })

    yield call(processInvoiceOrBillAndReconcile, bankLineMatch, bill)
    notify({
      id: NotificationKeys.BankReconciliationTransactionCreatedAndReconcile,
      message: i18next.t('bankreconciliation.create_invoice_bill.created_and_reconciled_success'),
      variant: 'success',
    })
  } catch (e) {
    notify({
      id: NotificationKeys.BankReconciliationTransactionCreatedAndReconcile,
      message: i18next.t('bankreconciliation.create_invoice_bill.created_and_reconciled_failure'),
      variant: 'error',
    })
  }
}

// --------------------------------------
// Bulk line operations
// --------------------------------------

function* deleteBankLines({ payload: { matchIds } }: DeleteBankLinesAction) {
  const bankLineMatches: any[] = yield select((state: SpecificState) => state.bankReconciliation.bankLineMatches)
  const lineIds: string[] = matchIds
    .map((matchId) => {
      const match = bankLineMatches.find((match: ExtendedBankLineMatch) => match.id === matchId)
      return match?.lineIds || []
    })
    .flat()

  if (lineIds.length) {
    const deletedLineIds: string[] = []
    yield all(
      lineIds.map((id) =>
        call(function* () {
          try {
            yield call(deleteRequest, `/v2/bankLines/${id}`)
            deletedLineIds.push(id)
          } catch (e) {
            console.warn(`Bank line ${id} could not be deleted:`, e)
          }
        }),
      ),
    )

    if (deletedLineIds.length) {
      yield put(bankLineMatchesDeleted(deletedLineIds))
    }
  }
}

// ---------------------------------
// Miscellaneous
// ---------------------------------
function* updateBankLineMatch(match: string | ExtendedBankLineMatch, payload: Partial<ExtendedBankLineMatch>) {
  if (typeof match === 'string') {
    match = yield select((state: SpecificState) => {
      return state.bankReconciliation.bankLineMatches.find((bankLineMatch) => bankLineMatch.id === match)
    })

    if (!match) {
      return
    }
  }

  yield put(
    bankLineMatchUpdated({
      ...(match as ExtendedBankLineMatch),
      ...payload,
    }),
  )
}

function* processInvoiceOrBillAndReconcile(bankLineMatch: ExtendedBankLineMatch, subject: Invoice | Bill) {
  // Only bills have voucherNo and it is never falsy
  const type = (subject as Bill).voucherNo ? ReconcilablePostingType.Bill : ReconcilablePostingType.Invoice
  const {
    bankLineSubjectAssociations: [blsa],
  } = yield call(postRequest, '/v2/bankLineSubjectAssociations', {
    bankLineSubjectAssociation: {
      matchId: bankLineMatch.id,
      subjectReference: `${type}:${subject.id}`,
    },
  })

  yield put(bankLineSubjectAssociationCreated(blsa))
  yield call(putRequest, `/v2/bankLineMatches/${bankLineMatch.id}`, {
    bankLineMatch: {
      isApproved: true,
    },
  })

  const contact: Contact = yield select((state: SpecificState) => {
    return state.app.contacts?.find((contact: Contact) => contact.id === subject.contactId)
  })

  if (contact) {
    subject.contactName = contact.name
  }

  let billyTransaction: BillyTransaction
  if (type === ReconcilablePostingType.Bill) {
    billyTransaction = createBillyTransactionFromBill(subject as Bill)
  } else {
    billyTransaction = createBillyTransactionFromInvoice(subject as Invoice)
  }

  const showReconciled: boolean = yield select(
    (state: SpecificState) => state.bankReconciliation.filters.bankLineGroupFilters.showReconciled,
  )
  if (showReconciled) {
    yield call(updateBankLineMatch, bankLineMatch.id, {
      matchedBillyTransactions: bankLineMatch.matchedBillyTransactions.concat(billyTransaction),
      subjectAssociationIds: bankLineMatch.subjectAssociationIds.concat(blsa.id),
      isApproved: true,
    })
  } else {
    yield put(bankLineMatchRemoved(bankLineMatch.id))
  }
}

// ---------------------------------
// Utils
// ---------------------------------

/*
 * This is an exact copy of the server-side filtering. As we pull ALL transactions right now, we can simply
 * filter them locally instead of making another call - this should make it about 40% faster
 * @TODO investigate whether this is a good idea long-term
 */
function filterBillyTransactions(
  allBillyTransactions: BillyTransaction[],
  {
    filters: allFilters,
    fromDate,
    toDate,
  }: {
    filters: Filters
    fromDate: string
    toDate: string
  },
): BillyTransaction[] {
  if (!allBillyTransactions.length) {
    return []
  }

  const { transactionsPostingLimit, transactionsSortProperty, transactionsSortDirection } = allFilters

  const filteredTransactions = _(allBillyTransactions)
    .filter((billyTransaction) => filterBillyTransaction(billyTransaction, { fromDate, toDate, ...allFilters }))
    .map((billyTransaction) => ({
      ...billyTransaction,
      sideAmount: billyTransaction.amount * (billyTransaction.side === 'credit' ? -1 : 1),
    }))
    .orderBy([transactionsSortProperty]) // we need to do sortDirection outside of this chain - see below
    .map((billyTransaction) => _.omit(billyTransaction, 'sideAmount'))
    .value()

  // It is better to sort by DESC (the default is ASC) using reverse() instead of adding it into orderBy(). Apparently,
  // there is a code weirdness in lodash which makes orderBy not be completely consistent and thus unreliable
  // https://github.com/lodash/lodash/issues/3285
  if (transactionsSortDirection === 1) {
    filteredTransactions.reverse()
  }

  return filteredTransactions.slice(0, transactionsPostingLimit)
}

// @TODO used by the above function, read for details
function filterBillyTransaction(
  billyTransaction: BillyTransaction,
  {
    reconcilablePostingFilters: { showDrafts, types },
    reconcilablePostingSearchValue: q,
    fromDate: fromDateString,
    toDate: toDateString,
  }: FiltersWithParsedDateRange,
): boolean {
  const fromDate = fromDateString ? new Date(fromDateString) : null
  const toDate = toDateString ? new Date(toDateString) : null
  const isBankMatched = false // @TODO this needs to be implemented
  const entryDate = new Date(billyTransaction.entryDate)

  if (!types.includes(billyTransaction.type)) {
    return false
  }

  if (fromDate && isBefore(entryDate, fromDate)) {
    return false
  }

  if (toDate && isAfter(entryDate, toDate)) {
    return false
  }

  if (billyTransaction.isBankMatched !== isBankMatched) {
    return false
  }

  if (!showDrafts && billyTransaction.state !== 'approved') {
    return false
  }

  if (
    q &&
    `${billyTransaction.text} ${billyTransaction.description} ${billyTransaction.amount}`
      .toLowerCase()
      .indexOf(q.toLowerCase()) < 0
  ) {
    return false
  }

  return true
}

function* getAllBillyTransactions(organizationId: string, accountId: string): any {
  const limit = 10000
  const offset = 0

  const baseUrl = `/organizations/${organizationId}/accounts/${accountId}/reconcilablePostings`
  const retrievalUrl = `${baseUrl}?offset=${offset}&limit=${limit}`

  // An idea was is to cycle through the pages and retry all billy transactions. But this is based on a
  // wrong initial implementation where we pull everything to check the IDs locally. For now, let's simply increase
  // the limit, and then do the necessary (deep) refactor where the IDs are passed properly from the backend in
  // the first place so we don't need to do any filtering locally
  //
  // It is also relatively unlikely we will run into any account with more than a few hundred postings, though
  // they are usually over 500 (for two or so years)
  return yield call(getRequest, retrievalUrl)
}

function* getBankLineMatchInformation(account: Account, filters: FiltersWithParsedDateRange) {
  const {
    fromDate,
    toDate,
    bankLinesSortProperty,
    bankLinesSortDirection,
    bankLineGroupSearchValue,
    bankLineGroupFilters: { showManual, showReconciled, showMatched },
  } = filters

  const params = new URLSearchParams()

  params.append('accountId', account.id)
  params.append('entryDatePeriod', `dates:${fromDate}...${toDate}`)
  params.append('sortProperty', bankLinesSortProperty)
  params.append('sortDirection', bankLinesSortDirection === 1 ? 'DESC' : 'ASC')
  params.append(
    'include',
    'bankLineMatch.lines,bankLineMatch.subjectAssociations,bankLineSubjectAssociation.subject,bankLine.comments:embed',
  )
  params.append('pageSize', '200')
  params.append('q', bankLineGroupSearchValue)

  if (!(showReconciled && showManual && showMatched)) {
    params.append('isApproved', showReconciled.toString())
    if (showManual !== showMatched) {
      params.append('isMatched', `${showMatched || !showManual}`)
    }
  }

  let retrievalUrl = `/v2/bankLineMatches?${params.toString()}`

  const matchInformation: Omit<GetBankLineMatchesResponse, 'meta'> = {
    bankLines: [],
    bankLineMatches: [],
    bankLineSubjectAssociations: [],
  }

  while (true) {
    const response: GetBankLineMatchesResponse = yield call(getRequest, retrievalUrl)

    for (const domain of Object.keys(response)) {
      const values = response[domain]
      if (domain === 'meta' || !Array.isArray(values)) {
        continue
      }

      if (Array.isArray(matchInformation[domain])) {
        matchInformation[domain].push(...values)
      } else {
        matchInformation[domain] = values
      }
    }

    if (!response.meta.paging.nextUrl || response.meta.paging.page === response.meta.paging.pageCount) {
      break
    }

    retrievalUrl = response.meta.paging.nextUrl
  }

  return matchInformation
}

// ---------------------------------
// EXPORT ALL SAGAS
// ---------------------------------

export default function* (): any {
  yield all([
    yield takeLatest(BANK_CONNECTION_REQUESTED, fetchBankConnection),
    yield takeLatest(BANK_LINES_AND_TRANSACTIONS_REQUESTED, fetchBankLinesAndTransactions),
    yield takeLatest(BANK_LINES_LATEST_LINE_REQUESTED, fetchLatestBankLine),
    yield takeLatest(BANK_LINE_SUBJECT_ASSOCIATION_DELETE_REQUESTED, deleteBankLineSubjectAssociation),
    // Balance bar
    yield takeLatest(BANK_BALANCE_REQUESTED, bankBalanceRequested),
    // DND
    yield takeLatest(DND_BANK_LINES, dndBankLines),
    yield takeLatest(DND_BILLY_TRANSACTIONS, dndBillyTransactions),
    yield takeLatest(UNGROUP_BANK_LINE, ungroupBankLine),
    // Difference type
    yield takeLatest(SELECT_DIFFERENCE_TYPE, selectDifferenceType),
    // Reconcile
    yield takeLatest(RECONCILE_MATCH, reconcileMatch),
    yield takeLatest(UNRECONCILE_MATCH, unreconcileMatch),
    yield takeLatest(BULK_RECONCILE, bulkCreateAndReconcile),
    yield takeLatest(RECONCILE_ALL, reconcileAllApprovedMatches),
    yield takeLatest(CREATE_BILL_AND_RECONCILE, createBillAndReconcile),
    yield takeLatest(CREATE_INVOICE_AND_RECONCILE, createInvoiceAndReconcile),
    // Bulk line operations
    yield takeLatest(DELETE_BANK_LINES, deleteBankLines),
  ])
}
