import { useCallback } from 'react'

import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import { useCurrentUser } from '@modules-deprecated/app/user'
import { useIsAnnualReportAvailable } from '@views/annualReport/hooks/useIsAnnualReportAvailable'
import { useSalaryConnectionStatus } from '@views/salaryIntegration/hooks/useSalaryConnectionStatus'

import { MenuItemId } from '../utils/menuItems'

export const useShouldSkipMenuItems = () => {
  const { user } = useCurrentUser()
  const { umbrella } = useUmbrella()
  const { isSalaryAvailable } = useSalaryConnectionStatus()
  const isAnnualReportAvailable = useIsAnnualReportAvailable()

  const { isAdmin } = user || {}
  const isUmbrellaOwner = !!umbrella && umbrella?.ownerUserId === user?.id

  const shouldSkipRenderSalaryItem = useCallback(
    (menuId: MenuItemId) => menuId === 'salary' && !isSalaryAvailable,
    [isSalaryAvailable],
  )

  const shouldSkipRenderItem = useCallback(
    (menuId: MenuItemId) => {
      if (menuId === 'umbrellaSubscriptions') {
        return !isAdmin && !isUmbrellaOwner
      }

      // TODO: Remove when all users migrated to a new scope version
      if (menuId === 'annualReports') {
        return !isAnnualReportAvailable
      }

      if (shouldSkipRenderSalaryItem(menuId)) {
        return true
      }

      return false
    },
    [isAdmin, isAnnualReportAvailable, isUmbrellaOwner, shouldSkipRenderSalaryItem],
  )

  return shouldSkipRenderItem
}
