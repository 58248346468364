import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../enums/queryKeys'
import { APIError } from '../../../utils'
import { deleteScoutConnection } from '../query-api'

interface UseDeleteInvitationArgs {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useDeleteScoutConnection = ({ onError, onSuccess }: UseDeleteInvitationArgs = {}) => {
  const { organization } = useUserOrganization()
  const queryClient = useQueryClient()
  const { isLoading: isProcessing, mutate } = useMutation(
    async (connectionId: string) =>
      organization && (await deleteScoutConnection({ organizationId: organization.id, connectionId })),
    {
      onError,
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.ScoutConnections)
        onSuccess?.()
      },
    },
  )

  const deleteConnection = useCallback(
    (connectionId: string) => {
      return mutate(connectionId)
    },
    [mutate],
  )

  return {
    delete: deleteConnection,
    isProcessing,
  }
}
