import { Alert, Color, Flex, ProgressBar, Spacing, Text } from '@design-system'

import isNumber from 'lodash/isNumber'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useExternalInvoicesSyncProgress } from '../../contexts/externalInvoicesSyncProgressContext'
import * as Styled from './styles'

export const SyncProgressTopBar = (): ReactElement => {
  const { t } = useTranslation()
  const { processedDocumentsCount, progressPercent, totalDocumentsCount } = useExternalInvoicesSyncProgress()

  return (
    <Alert icon="roundedArrows" variant="info">
      <Flex alignItems="center" gap={Spacing.L}>
        <Text>{t('external_invoices_sync_top_bar.in_progress.syncing_in_progress')}</Text>
        <Styled.ProgressBarWrapper>
          <ProgressBar
            backgroundColor={Color.DayGrey}
            progress={progressPercent}
            progressColor={Color.Purple}
            size="small"
          />
        </Styled.ProgressBarWrapper>
        {isNumber(processedDocumentsCount) && isNumber(totalDocumentsCount) && (
          <Text color={Color.Purple} variant="micro">
            {t('external_invoices_sync_top_bar.in_progress.documents_out_of_total', {
              processedDocumentsCount,
              totalDocumentsCount,
            })}
          </Text>
        )}
      </Flex>
    </Alert>
  )
}
