import { Button } from '@design-system'

import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import { useEmberCurrentRoute } from '../../contexts/emberCurrentRouteContext'
import { useSideNavbar } from '../../contexts/sideNavbarContext'
import { Translate } from '../Translate'
import { MenuAccountSelector } from './elements/MenuAccountSelector'
import { MenuActions } from './elements/MenuActions'
import { MenuBadge } from './elements/MenuBadge'
import { MenuFooter } from './elements/MenuFooter'
import { MenuHeader } from './elements/MenuHeader'
import { MenuItems } from './elements/MenuItems'
import { UpsellWidget } from './elements/UpsellWidget'
import * as Styled from './styles'

export const Menu = (): ReactElement => {
  const [isOpenedByUser, setIsOpenedByUser] = useState(false)
  const { currentRoutePath } = useEmberCurrentRoute()
  const { withSideNavbar } = useSideNavbar()

  useEffect(() => {
    setIsOpenedByUser(false)
  }, [currentRoutePath])

  const handleCloseClick = useCallback(() => {
    setIsOpenedByUser(false)
  }, [])

  const handleOpenClick = useCallback(() => {
    setIsOpenedByUser(true)
  }, [])

  return (
    <>
      <Styled.MenuWrapper forceOpen={isOpenedByUser} isMovedToRight={withSideNavbar}>
        <MenuHeader onCloseClick={handleCloseClick} />
        <MenuBadge />
        <MenuActions />
        <MenuItems closeMenu={handleCloseClick} />
        <UpsellWidget />
        <MenuAccountSelector />
        <MenuFooter />
      </Styled.MenuWrapper>

      <Styled.OpenButtonWrapper onClick={handleOpenClick} visible={!isOpenedByUser} isMovedToRight={withSideNavbar}>
        <Button icon="burger" size="xxl">
          <Translate value="menu" />
        </Button>
      </Styled.OpenButtonWrapper>

      <Styled.Overlay onClick={handleCloseClick} visible={isOpenedByUser} />
    </>
  )
}
