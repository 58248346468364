import { Input } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../../../hooks'
import { InvoiceAndProductSettingsFormSchema } from '../../../../../../utils/formData'

export const NextInvoiceNumber = (): ReactElement | null => {
  const { t } = useTranslation()
  const { FormItem, watch } = useFormContext<InvoiceAndProductSettingsFormSchema>()
  const shouldShowNextInvoiceNoField = watch('shouldShowNextInvoiceNoField')

  if (!shouldShowNextInvoiceNoField) {
    return null
  }

  return (
    <FormItem
      label={t('settings.organization.invoice.invoice_settings.next_invoice_no.label')}
      name="nextInvoiceNo"
      render={({ field }) => <Input {...field} type="number" />}
    />
  )
}
