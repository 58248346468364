import { useCallback, useEffect, useMemo, useState } from 'react'

import { useUpdateOrganizationSettings, useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import { OrganizationSettingsValue } from '@modules-deprecated/app/organization/types/organizationSettingsValue'

import { IS_BETA_CANDIDATE_SETTING } from '../constants/isBetaCandidateSetting'

interface UseOrganizationCandidateSettingProps {
  organizationId?: string
}

interface UseOrganizationCandidateSettingResponse {
  isFeaturesCandidate: boolean
  isLoading: boolean
  setCandidateSetting: () => void
  unsetCandidateSetting: () => void
}

export const useOrganizationCandidateSetting = ({
  organizationId,
}: UseOrganizationCandidateSettingProps): UseOrganizationCandidateSettingResponse => {
  const { isLoading: isOrganizationSettingsLoading, organizationSettings } = useUserOrganizationSettings()
  const { update: updateSettings, isLoading: isUpdating } = useUpdateOrganizationSettings()

  const isFeaturesCandidateDefault = useMemo(
    () => organizationSettings?.[IS_BETA_CANDIDATE_SETTING] === '1',
    [organizationSettings],
  )

  const [isFeaturesCandidate, setIsFeatureCandidate] = useState(isFeaturesCandidateDefault)

  useEffect(() => {
    // Update the isFeatureCandidate property from the DB
    setIsFeatureCandidate(isFeaturesCandidateDefault)
  }, [isFeaturesCandidateDefault])

  const set = useCallback(
    (value: OrganizationSettingsValue) => {
      if (organizationId) {
        updateSettings({
          key: IS_BETA_CANDIDATE_SETTING,
          organizationId,
          value,
        })

        setIsFeatureCandidate(value === '1')
      } else {
        // Improbable case but confirming the call is not made
        console.error('Organization id was not set correctly')
      }
    },
    [organizationId, updateSettings],
  )

  const setCandidateSetting = useCallback(() => set('1'), [set])

  const unsetCandidateSetting = useCallback(() => set('0'), [set])

  return {
    isFeaturesCandidate,
    isLoading: isOrganizationSettingsLoading || isUpdating,
    setCandidateSetting,
    unsetCandidateSetting,
  }
}
